import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Module extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Module și integrări - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Descoperă lista modulelor ce pot fi integrate în magazinul tău online dezvoltat pe OpenCart"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/module-integrari-magazine-online" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Module și integrări</h1>
                              <p>Module și integrări ce pot fi implementate in cadrul unui magazin online dezvoltat cu OpenCart</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Plăti online</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="mobilPay" title="mobilPay" width="300" height="auto" src="https://ocshop.ro/assets/img/module/mobilPay.webp"></img>
                                   <p>Mobilpay.ro</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="euplatesc.ro" title="euplatesc.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/euplatesc.webp"></img>
                                   <p>euplatesc.ro</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="librapay.ro" title="librapay.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/LibraPay.webp"></img>
                                   <p>librapay.ro</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="paypal.com" title="paypal.com" width="300" height="auto" src="https://ocshop.ro/assets/img/module/PayPal.webp"></img>
                                   <p>paypal.com</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Curierat Self AWB</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="fancourier.ro" title="fancourier.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/FanCurier.webp"></img>
                                   <p>fancourier.ro</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="urgentcargus.ro" title="urgentcargus.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/UrgentCargus.webp"></img>
                                   <p>urgentcargus.ro</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="dpd.com" title="dpd.com" width="300" height="auto" src="https://ocshop.ro/assets/img/module/DPD.webp"></img>
                                   <p>dpd.com</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Facturare</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="smartbill.ro" title="smartbill.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/SmartBill.webp"></img>
                                   <p>smartbill.ro</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Marketing</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="mailchimp.com" title="mailchimp.com" width="300" height="auto" src="https://ocshop.ro/assets/img/module/mailChimp.webp"></img>
                                   <p>mailchimp.com</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="profitshare.ro" title="profitshare.ro" width="300" height="auto" src="https://ocshop.ro/assets/img/module/ProfitShare.webp"></img>
                                   <p>profitshare.ro</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Social</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Login with Facebook" title="Login with Facebook" width="300" height="auto" src="https://ocshop.ro/assets/img/module/facebook-login.webp"></img>
                                   <p>Login with Facebook</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Facebook Shop" title="Facebook Shop" width="300" height="auto" src="https://ocshop.ro/assets/img/module/facebook-shop.webp"></img>
                                   <p>Facebook Shop</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Google Shopping" title="Google Shopping" width="300" height="auto" src="https://ocshop.ro/assets/img/module/GoogleShopping.webp"></img>
                                   <p>Google Shopping</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Social Share" title="Social Share" width="300" height="auto" src="https://ocshop.ro/assets/img/module/SocialShare.webp"></img>
                                   <p>Social Share</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Rapoarte</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Google Analitycs" title="Google Analitycs" width="300" height="auto" src="https://ocshop.ro/assets/img/module/GoogleAnalitycs.webp"></img>
                                   <p>Google Analitycs</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="Google Tag Manager" title="Google Tag Manager" width="300" height="auto" src="https://ocshop.ro/assets/img/module/GoogleTagManager.webp"></img>
                                   <p>Google Tag Manager</p>
                              </Col>
                         </Row>
                    </Container>
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>Chat</h2>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="zendesk.com" title="zendesk.com" width="300" height="auto" src="https://ocshop.ro/assets/img/module/ZendeskChat.webp"></img>
                                   <p>zendesk.com</p>
                              </Col>
                              <Col xs={6} md={6} lg={3} className="modul">
                                   <img alt="tawk.to" title="tawk.to" width="300" height="auto" src="https://ocshop.ro/assets/img/module/TawkTo.webp"></img>
                                   <p>tawk.to</p>
                              </Col>
                         </Row>
                    </Container>
               </div>

            </div>
    		
          )
     }
}


export default Module 