import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

import Clients from '../components/Clients';

class Despre extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Despre OcShop -  OcShop.ro</title>
                    <meta
                    name="description"
                    content="Solutii e-commerce complete folosind platforma ecommerce OCShop"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/despre" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Soluții e-commerce complete cu OcShop</h1>
                              <p>Află care sunt avantajele folosirii platformei OpenCart</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12} className="intro">
                              <h2>De ce să alegi <strong>OCShop?</strong></h2>
                              <p>Platforma OpenCart este o platforma de management al conținutului Open-Source prin intermediul căreia se pot realiza magazine online de succes. Devenind tot mai populară în ultima vreme, platforma este recomandată pentru afacerile mici și mijlocii ce doresc dezvoltarea unei afaceri online cu un potențial ridicat de succes. Iată câteva dintre principalele avantaje ale acestei platforme open-source:</p>
                              </Col>
                         </Row>
                    </Container> 
                    <Container className="avantaje">
                         <Row>
                              <Col xs={12} md={12} lg={4}>
                                   <h3>Platformă scalabilă</h3>
                                   <p>Magazinul tău online va permite gestionarea unui număr mare de comenzi, clienți și produse.</p>
                                   <h3>SEO & Mobile Friendly</h3>
                                   <p>Magazinele online dezvoltate de noi, vin la pachet cu design responsive ( adaptat la toate tipurile de dispozitive ) și funcții SEO suplimentare față de versiunea default pentru a crește rapid vânzările.</p>
                                   <h3>Optimizat pentru conversie</h3>
                                   <p>Magazinul tau online va dispune de bune practici de optimizare a ratei de conversie, astfel incat vizitatorii sa gaseasca rapid usor produsele de care au nevoie.</p>
                              </Col>
                              <Col xs={12} md={12} lg={4}>
                                   <img alt="Avantaje utilizare OpenCart" title="Avantaje utilizare OpenCart" width="510" height="auto" src="https://ocshop.ro/assets/img/avantaje-opencart.webp"></img>
                              </Col>
                              <Col xs={12} md={12} lg={4}>
                                   <h3>Integrări multiple</h3>
                                   <p>Integrăm în magazinul tău online module extrem de utile (plata cu cardul, transport și generarea de AWB-uri, facturare rapidă direct din magazin cu SmartBill)</p>
                                   <h3>Extensibilitate</h3>
                                   <p>Platforma dispune de o gama largă de module și extensie disponibile pentru magazinul tău online. Astfel poți extinde oricând funcționalitatea magazinului tău online.</p>
                                   <h3>Funcționalitate Mulți-Store</h3>
                                   <p>Un avantaj major al platformei OpenCart este acela că poți gestiona mai multe magazine dintr-un singur panou de administrare.</p>
                              </Col>
                         </Row>
                    </Container>
                    <div class="calltoaction">
                         <Container>
                              <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h5>Hai să discutăm despre proiectul tău.</h5>
                                   <Button href="tel:+40763418689" variant="primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                             <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                        </svg>
                                        Sună acum  
                                   </Button>
                              </Col>
                              </Row>
                         </Container>
                    </div>
                    <Clients />
               </div>

            </div>
    		
          )
     }
}


export default Despre 