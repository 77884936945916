import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import "./Odometers.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

function onChange (isVisible) {
  
}

class Odometers extends Component {

  render(){
       return(
            <div class="odometers">
                <Container className="counter">
                    <Row>
                        <Col xs={6} md={6} lg={3}>
                            <div class="odometers">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-collection" viewBox="0 0 16 16">
                                    <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z"/>
                                    </svg>
                                    <span>
                                    <CountUp start={0} end={12} duration={1}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                            </VisibilitySensor>   
                                        )}
                                    </CountUp>
                                    </span><br/>Ani Experiență
                                </p>
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div class="odometers">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-code-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/>
                                    </svg>
                                    <span>
                                    <CountUp start={0} end={35} duration={1}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                            </VisibilitySensor>   
                                        )}
                                    </CountUp>
                                     +</span><br/>Proiecte Efectuate
                                </p>
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div class="odometers">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                    </svg>
                                    <span>
                                    <CountUp start={0} end={150} duration={1}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                            </VisibilitySensor>   
                                        )}
                                    </CountUp>
                                     +</span><br/>Clienți Mulțumiți
                                </p>
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div class="odometers">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16">
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
                                    </svg>
                                    <span>
                                    <CountUp start={0} end={12} duration={1}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                            </VisibilitySensor>   
                                        )}
                                    </CountUp>
                                     +</span><br/>Gamă Servicii
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
       )
  }
}

export default Odometers 