import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Portofoliu extends Component {
     render(){
     
          return(

            <div>

            <Helmet>
                <title>Portofoliu magazin online OpenCart - OcShop.ro</title>
                <meta
                name="description"
                content="Decopera o parte din proiectele noastre dezoltate pe OpenCart. Sună acum și hai să stăm de vorba!"
                />;
                <link rel="canonical" href="http://ocshop.ro/portofoliu-magazine-online" />
            </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Portofoliu magazine online</h1>
                              <p>O parte din clienții noștrii care au ales crearea unui magazin online cu OpenCart</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Aprozar online cu livare în București - AprozarLaNico.ro" target="_blank" href="https://aprozarlanico.ro/"><img alt="Aprozar online cu livare în București - AprozarLaNico.ro" title="Aprozar online cu livare în București - AprozarLaNico.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/aprozarlanico.webp"/></a> <br/>
                                   aprozarlanico.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu uși de interior și exterior - grandsecret.ro" target="_blank" href="https://grandsecret.ro/"><img alt="Magazin online cu uși de interior și exterior - grandsecret.ro" title="Magazin online cu uși de interior și exterior - grandsecret.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/grandsecret.webp"/></a> <br/>
                                   grandsecret.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Marketplace pentru afacerile mici - tepromovezi.ro" target="_blank" href="https://tepromovezi.ro/"><img alt="Marketplace pentru afacerile mici - tepromovezi.ro" title="Marketplace pentru afacerile mici - tepromovezi.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/tepromovezi.ro.webp"/></a> <br/>
                                   tepromovezi.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu produse pentru copii - kiddyshop.ro" target="_blank" href="https://kiddyshop.ro/"><img alt="Magazin online cu produse pentru copii - kiddyshop.ro" title="Magazin online cu produse pentru copii - kiddyshop.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/kiddyshop.ro.webp"/></a> <br/>
                                   kiddyshop.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online sisteme de irigatii - epicurare.ro" target="_blank" href="https://epicurare.ro/"><img alt="Magazin online sisteme de irigatii - epicurare.ro" title="Magazin online sisteme de irigatii - epicurare.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/epicurare.webp"/></a> <br/>
                                   epicurare.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online biciclete si piese de schimb - onebike.ro" target="_blank" href="https://onebike.ro/"><img alt="Magazin online biciclete si piese de schimb - onebike.ro" title="Magazin online biciclete si piese de schimb - onebike.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/onebike.webp"/></a> <br/>
                                   onebike.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online stingatoare de incendiu si echipamente PSI -  e-stingatoare.ro" target="_blank" href="https://e-stingatoare.ro/"><img alt="Magazin online stingatoare de incendiu si echipamente PSI -  e-stingatoare.ro" title="Magazin online stingatoare de incendiu si echipamente PSI -  e-stingatoare.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/e-stingatoare.webp"/></a> <br/>
                                   e-stingatoare.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online sigurante, saibe si furtunuri - tassiagrup.ro" target="_blank" href="https://tassiagrup.ro/"><img alt="Magazin online sigurante, saibe si furtunuri - tassiagrup.ro" title="Magazin online sigurante, saibe si furtunuri - tassiagrup.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/tassiagrup.webp"/></a> <br/>
                                   tassiagrup.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online roti si anvelope agricole - ovitires.ro" target="_blank" href="https://ovitires.ro/"><img alt="Magazin online roti si anvelope agricole - ovitires.ro" title="Magazin online roti si anvelope agricole - ovitires.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/ovitires.webp"/></a> <br/>
                                   ovitires.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu pinata pentru petreceri - pinatapetreceri.ro" target="_blank" href="https://pinatapetreceri.ro/"><img alt="Magazin online cu pinata pentru petreceri - pinatapetreceri.ro" title="Magazin online cu pinata pentru petreceri - pinatapetreceri.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/pinatapetreceri.webp"/></a> <br/>
                                   pinatapetreceri.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu echipamente pentru spalatorii auto - davidex.ro" target="_blank" href="https://www.davidex.ro/"><img alt="Magazin online cu echipamente pentru spalatorii auto - davidex.ro" title="Magazin online cu echipamente pentru spalatorii auto - davidex.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/davidex.webp"/></a> <br/>
                                   davidex.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu extensii pentru par - extensiilux.com" target="_blank" href="https://www.extensiilux.com"><img alt="Magazin online cu extensii pentru par - extensiilux.com" title="Magazin online cu extensii pentru par - extensiilux.com" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/extensiilux.webp"/></a> <br/>
                                   extensiilux.com
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu parfumuri si produse cosmetice - shopmachiaj.ro" target="_blank" href="https://shopmachiaj.ro/"><img alt="Magazin online cu parfumuri si produse cosmetice - shopmachiaj.ro" title="Magazin online cu parfumuri si produse cosmetice - shopmachiaj.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/shopmachiaj.webp"/></a> <br/>
                                   shopmachiaj.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu mobilier de lux - edormitoare.ro" target="_blank" href="https://edormitoare.ro/"><img alt="Magazin online cu mobilier de lux - edormitoare.ro" title="Magazin online cu mobilier de lux - edormitoare.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/edormitoare.webp"/></a> <br/>
                                   edormitoare.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu mobilier pentru saloane de infrumusetare - mobiliersalon.ro" target="_blank" href="https://mobiliersalon.ro/"><img alt="Magazin online cu mobilier pentru saloane de infrumusetare - mobiliersalon.ro" title="Magazin online cu mobilier pentru saloane de infrumusetare - mobiliersalon.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/mobiliersalon.webp"/></a> <br/>
                                   mobiliersalon.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu produse pentru copii cu nevoie speciale - ajutamasacresc.ro" target="_blank" href="https://ajutamasacresc.ro/"><img alt="Magazin online cu produse pentru copii cu nevoie speciale - ajutamasacresc.ro" title="Magazin online cu produse pentru copii cu nevoie speciale - ajutamasacresc.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/ajutamasacresc.webp"/></a> <br/>
                                   ajutamasacresc.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Magazin online cu mobila la comanda - nourdesign.ro" target="_blank" href="https://www.nourdesign.ro/"><img alt="Magazin online cu mobila la comanda - nourdesign.ro" title="Magazin online cu mobila la comanda - nourdesign.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/nourdesign.webp"/></a> <br/>
                                   nourdesign.ro
                              </Col>
                              <Col xs={6} md={6} lg={4} className="model">
                                   <a title="Supermarket online cu o gama variata de produse - fiorino.ro" target="_blank" href="https://www.fiorino.ro/"><img alt="Supermarket online cu o gama variata de produse - fiorino.ro" title="Supermarket online cu o gama variata de produse - fiorino.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/fiorino.webp"/></a> <br/>
                                   fiorino.ro
                              </Col>
                         </Row>
                    </Container>
               </div>

            </div>
    		
          )
     }
}


export default Portofoliu 