import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Functionalitati extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Funcționalități - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Descopera lista de Funcționalități pentru magazinele online dezvoltate pe OpenCart"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/functionalitati-magazin-online" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Funcționalități magazin online</h1>
                              <p>Principalele funcționalități ale unui magazin online dezvoltat cu OpenCart</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container className="block_content1">
                         <Row>
                              <Col xs={12} md={12} lg={6}>
                                   <h3>Design Responsive</h3>
                                   <p>Îți punem la dispoziție teme profesionale cu design responsive ce pot fi personalizate pentru orice tip de afacere. Indiferent de tipul tău de business și de nevoile tale, temele puse la dispoziție sunt  configurate și optimizate pentru rezultate rapide.</p>
                                   <p>Pe lângă acestea, ținem cont și de viteză de încărcare a magazinului tău pentru a oferi o rată crescută de conversie și pentru o bună experiență în navigare.</p>
                                   <p>Magazinul tău online va fi optimizat pentru mobil, tabletă, notebook și PC. Dacă dorești un magazin online, rapid și profesional îți stăm dispoziție pentru a beneficia de un magazin online optimizat spre succesul afacerii tale.</p>
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                   <img alt="Design responsive magazin online" title="Design responsive magazin online" width="920" height="auto" src="https://ocshop.ro/assets/img/design-responsive-magazin-online.webp"></img>
                              </Col>
                         </Row>
                    </Container>
                    <div class="block_content2">
                         <Container>
                              <Row>
                                   <Col xs={12} md={12} lg={6}>
                                        <img alt="Interfață de administrare magazin online" title="Interfață de administrare magazin online" width="785" height="auto" src="https://ocshop.ro/assets/img/interfata-administrare-magazin-online.webp"></img>
                                   </Col>
                                   <Col xs={12} md={12} lg={6}>
                                        <h3>Interfață de administrare ușor de folosit</h3>
                                        <p>Platforma OpenCart dispune de un panou de administrare ușor de utilizat pentru a manageria toate informatiiile tale. </p>
                                        <p>Prin intermediul acestui panou de aministrare poți adaugă, edita și șterge produsele tale, selecta produse similare și adaugă atribute multiple pentru fiecare produs.</p>
                                        <p>Pe lângă acestea, pachetul Business beneficiază de implementarea funcționalității de import/export a produselor  din fișierele CSV, XLS ce va reduce semnificativ timpul alocat încărcării și actualizării produselor. </p>
                                        <p>Prin intermediul acestei funcționalități ai posibilitatea de a actualiza prețurile, stabilind în același timp atributele și opțiunile produselor.</p>
                                   </Col>
                              </Row>
                         </Container>
                    </div>
                    <Container className="block_content1">
                         <Row>
                              <Col xs={12} md={12} lg={6}>
                                   <h3>Optimizare SEO</h3>
                                   <p>Magazinul tău online va beneficia de bune practici de optimizare a ratei de conversie și de funcții SEO suplimentare pentru a fi cât mai vizibil online. Am dezvoltat nou functionalizati pentru a crește vânzările sioptimizarea SEO a magazinului tău .</p>
                                   <p>Pentru că vizitatorii să vizualizeze rapid și ușor produsele de care au nevoie, am introdus Quick View ce oferă posibilitatea vizualizării detaliilor produselor într-o fereastră de tip pop-up.</p>
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                   <img alt="Optimizare SEO magazin online" title="Optimizare SEO magazin online" width="920" height="auto" src="https://ocshop.ro/assets/img/optimizare-seo-magazin-online.webp"></img>
                              </Col>
                         </Row>
                    </Container>
                    <div class="block_content2">
                         <Container>
                              <Row>
                                   <Col xs={12} md={12} lg={6}>
                                        <img alt="Rapoarte și statistici magazin online" title="Rapoarte și statistici magazin online" width="920" height="auto" src="https://ocshop.ro/assets/img/rapoarte-magazin-online.webp"></img>
                                   </Col>
                                   <Col xs={12} md={12} lg={6}>
                                        <h3>Rapoarte și statistici</h3>
                                        <p>Pe lângă modulele existente în cadrul platformei, magazinul tău online va beneficia de implementarea Google Analytics ce va permite obținerea unor rapoarte detaliate despre traficul site-ului, numărul de vizitatori, paginile de intrare și de ieșire, cuvintele cheie utilizate etc.</p>
                                        <p>Pe lângă această unelta oferită de Google, configurăm magazinul tău online în Google WebMaster Tools pentru obținerea unui control deplin acestuia, putând măsură traficul de căutare și performanță acestuia.</p>
                                   </Col>
                              </Row>
                         </Container>
                    </div>
               </div>

            </div>
    		
          )
     }
}


export default Functionalitati 