import React,{ Component } from 'react'
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

import Call from '../components/Call';
import Clients from '../components/Clients';

class Magazin extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Magazin online la cheie - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Descoperă magazinele online la cheie create pe OpenCart și începe să vinzi online chiar acum"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/magazin-online-la-cheie" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Magazin online la cheie</h1>
                              <p>Magazin online la cheie pregătit pentru tine</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12} className="intro">
                              <h2>Magazin online la cheie folosind platforma ecommerce OCShop</h2>
                              <p>Dispunem de magazine online la cheie pregătite să vândă în orice moment.</p>
                              <p>Dacă doriți să creșteți vânzările companiei prin crearea unui magazin online profesional vă stăm la dispoziție pentru a beneficia de un magazin online la cheie optimizat spre vânzarea produselor. <br/><br/><br/></p>
                              </Col>
                         </Row>
                    </Container>
                    <Call />
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12} className="pasi">
                              <h2><strong>3 pasi</strong> simpli pentru magazinul tau</h2>
                              <p>Proces simplu de obținere al unui magazin online la cheie, optimizat pentru succesul afacerii tale</p>
                              </Col>
                              <Col xs={12} md={12} lg={4} className="pas">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-aspect-ratio" viewBox="0 0 16 16">
                                   <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
                                   <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
                                   </svg>
                                   <h3>1. ALEGI DESIGN-UL PREFERAT</h3>
                                   <p>Primul pas este alegerea designul preferat potrivit afacerii tale</p>
                              </Col>
                              <Col xs={12} md={12} lg={4} className="pas">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-code-slash" viewBox="0 0 16 16">
                                        <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z"/>
                                   </svg>
                                   <h3>2. ÎȚI PERSONALIZĂM MAGAZINUL</h3>
                                   <p>Personalizăm magazinul cu logo, bannere și culori care să reflecte brandul tău</p>
                              </Col>
                              <Col xs={12} md={12} lg={4} className="pas">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
                                        <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
                                   </svg>
                                   <h3>3. GATA PENTRU VÂNZĂRI</h3>
                                   <p>Adaugi produsele și lansăm magazinul online. Acum ești gata pentru vânzări</p>
                              </Col>
                         </Row>
                    </Container>
                    <Clients />
               </div>

            </div>
    		
          )
     }
}


export default Magazin 