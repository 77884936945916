import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import "./Banner.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

class Banner extends Component {
  render(){
       return(
            <div class="banner">
            <Container>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h1>Magazine online <br/> în OpenCart</h1>
                  <p>Vrei să începi o afacere online?</p>
                  <p>Soluții e-commerce complete cu OcShop</p>
                  <p>
                  <Button href="tel:+40763418689" variant="primary">
                  Cere ofertă
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                  </svg>
                </Button>
                  </p>
                </Col>
                <Col xs={12} md={6} lg={6}>
                <img src="https://ocshop.ro/assets/img/development.svg" alt="Creare magazine online" title="Creare magazine online în Opencart" width="234" height="auto" />
                </Col>
              </Row>
            </Container>
            </div>
       )
  }
}

export default Banner 