import React,{ Component } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Banner from './components/Banner';
import Blocks from './components/Blocks';
import About from './components/About';
import Odometers from './components/Odometers';
import Avantages from './components/Avantages';
import Clients from './components/Clients';
import Call from './components/Call';

function App() {
  return (
    <div>
        <Banner />
        <Blocks />
        <About />
        <Odometers />
        <Avantages />
        <Clients />
        <Call />
    </div>
  );
}

export default App;