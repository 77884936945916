import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Contact extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Contactează-ne acum - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Contactează-ne acum și hai să stăm de vorba despre magazinul tău online"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/contact" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Contactează-ne acum - OcShop.ro</h1>
                              <p>Dorești să afli mai multe detalii despre serviciile oferite? Contactează-ne acum folosind datele de mai jos</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={6} lg={6}>
                                   <h2><strong>Dualvalcost Solution SRL</strong></h2>
                                   <p><strong>Adresa:</strong> Str. Garii, nr. 24, Cretesti, jud. Ilfov</p>
                                   <p><strong>CUI:</strong> 26823086</p>
                                   <p><strong>Reg. Com.</strong> J23/1237/2010</p>
                                   <p><strong>Email:</strong> contact@ocshop.ro</p>
                                   <p><strong>Mobil:</strong> +40 763 418 689</p>
                              </Col>
                              <Col xs={12} md={6} lg={6} className="contact">
                                   <p>Dacă sunteți interesați de serviciile oferite de compania noastră, ne puteți contacta utilizând datele alăturate.</p>
                                   <img alt="Contactează-ne acum - OcShop.ro" title="Contactează-ne acum - OcShop.ro" src="https://ocshop.ro/assets/img/contact.webp"></img>
                              </Col>
                         </Row>
                    </Container>
               </div>

            </div>
    		
          )
     }
}


export default Contact 