import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Optimizare extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Optimizare SEO Opencart pentru magazinul tău online - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Optimizare SEO Opencart. Deții un magazin online în Opencart și dorești serviciul de optimizare SEO Opencart? Contactează-ne acum!"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/optimizare-seo-opencart" />
               </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Optimizare SEO Opencart</h1>
                              <p>Optimizare SEO Opencart pentru creșterea vizibilității</p>
                              </Col>
                         </Row>
                    </Container>
               </div>
               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12} className="intro">
                              <h2>Optimizare SEO Opencart</h2>
                              <p>Crește vizibilitatea magazinului tău online prin optimizarea SEO a acestuia.<br/><br/><br/></p>
                              </Col>
                         </Row>
                    </Container>
                    <Container className="block_content1">
                         <Row>
                              <Col xs={12} md={12} lg={6}>
                                   <h3>Analizăm magazinul online</h3>
                                   <h4>Audit SEO Opencart</h4>
                                   <p>Analizăm principalele pagini ale magazinului tău online pentru a vedea dacă acesta respectă bunele practici de ecommerce.</p>
                                   <p>Pe lângă acestea, îți vom analiză codul sursă al magazinului, viteză de încărcare a paginilor, concurență online și poziționarea magazinului în principalele motoare de căutare după principale cuvinte cheie.</p>
                                   <p>În această etapă vei află care este situația actuală a magazinului tău online și care sunt etapele pentru creșterea vizibilității magazinului tău online.</p>
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                   <img alt="Audit SEO Opencart" title="Audit SEO Opencart" width="920" height="auto" src="https://ocshop.ro/assets/img/audit-magazin-online.webp"></img>
                              </Col>
                         </Row>
                    </Container>
                    <div class="block_content2">
                         <Container>
                              <Row>
                                   <Col xs={12} md={12} lg={6}>
                                        <img alt="Funcționalități magazin online" title="Funcționalități magazin online" width="920" height="auto" src="https://ocshop.ro/assets/img/optimizare-seo-opencart.webp"></img>
                                   </Col>
                                   <Col xs={12} md={12} lg={6}>
                                        <h3>Implementăm noi funcționalități</h3>
                                        <p>Pentru că magazinul tău online să fie cât mai vizibil, vom implementa noi functionalizati pentru creșterea ratei de conversie a site-ului. Față de versiunea Default a OpenCart, magazinul tău online va beneficia de :</p>
                                        <p>- Implementarea atributelor Headings (H1, H2, H3) pentru categorii, produse și pagini</p>
                                        <p>- Implementarea atributelor ALT și Title pentru imaginea principală a produselor și categoriilor</p>
                                        <p>- Optimizarea codului sursă al site-ului, actualizarea temei pentru crearea densității cuvintelor cheie etc.</p>
                                   </Col>
                              </Row>
                         </Container>
                    </div>
                    <Container className="block_content1">
                         <Row>
                              <Col xs={12} md={12} lg={6}>
                                   <h3>Creștem viteză de încărcare</h3>
                                   <p>Un factor important în poziționarea site-ului în motoarele de căutare este viteză de încărcare a site-ului, motiv pentru care vom optimiza magazinul tău online pentru obținerea unui scor cât mai bun la testele de încărcare a site-ului.</p>
                                   <p>În această etapă, optimizăm imaginile magazinului, activăm și configurăm comprimarea fișierelor, reducem dimensiunea fișierelor javascript și css, etc.</p>
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                   <img alt="Creștere viteză de încărcare magazin online Opencart" title="Creștere viteză de încărcare magazin online Opencart" width="920" height="auto" src="https://ocshop.ro/assets/img/optimizare-viteza-magazin-online-opencart.webp"></img>
                              </Col>
                         </Row>
                    </Container>
                    <div class="block_content2">
                         <Container>
                              <Row>
                                   <Col xs={12} md={12} lg={6}>
                                        <img alt="Rapoarte și statistici magazin online" title="Rapoarte și statistici magazin online" width="920" height="auto" src="https://ocshop.ro/assets/img/rapoarte-magazin-online.webp"></img>
                                   </Col>
                                   <Col xs={12} md={12} lg={6}>
                                        <h3>Îți furnizăm rapoarte și statistici</h3>
                                        <p>În acesta etapă va oferim rapoarte și statistici despre optimizarea magazinului tău online, iar pentru obținerea unui control deplin acestuia magazinului online, vom implementa Google Analytic pentru a măsură traficul de căutare și performanță acestuia.</p>
                                   </Col>
                              </Row>
                         </Container>
                    </div>
               </div>

            </div>
    		
          )
     }
}


export default Optimizare 