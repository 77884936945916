import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Termeni extends Component {
     render(){
     
          return(

            <div>

               <Helmet>
                    <title>Termeni și condiții - OcShop.ro</title>
                    <meta
                    name="description"
                    content="Termenii și condiții de utilizare ale site-ului OcShop.ro"
                    />;
                    <link rel="canonical" href="http://ocshop.ro/termeni-si-conditii" />
               </Helmet>
               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                               <h1>Termeni și condiții</h1>
                               <p>Termenii și condiții de utilizare ale site-ului OcShop.ro</p>
                              </Col>
                         </Row>
                    </Container>
               </div>

               <div class="content">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                               <p>Site-ul ocshop.ro este administrat de catre Dualvalcost Solution SRL cu sediul in localitatea Str. Garii, nr. 24, Cretesti, Ilfov, Romania, inregistrata la Oficiul Registrului Comertului sub nr. J23/1237/2010 Cod de Identificare Fiscala 2682086, Tel. +40 763418689, Email: contact@ocshop.ro</p>
                               <p>Va rugam sa cititi acesti termeni si conditii cu atentie pentru folosirea in cele mai bune conditii a site-ului. Utilizarea acestui site implica acceptarea tacita a termenilor si a conditiilor de utilizare prezentate mai jos, care reprezinta intelegerea (contractul) dintre parti.</p>
                               <p>In calitate de autor/ proprietar/ administrator al site-ului ocshop.ro, Dualvalcost Solution SRL isi rezerva dreptul de a schimba si actualiza in orice moment continutul acestuia, precum si Politica de confidentialitate, Termenii si conditiile de folosire, fara o notificare prealabila. Astfel, va rugam sa vizitati periodic aceasta sectiune pentru a verifica termenii si conditiile pe care ati agreat sa le respectati.</p>
                               <p><strong>1 - SCOPUL</strong></p>
                               <p>Termenii si conditiile generale de vanzare (in cele ce urmeaza TCGV) se vor aplica tuturor vanzarilor de servicii de catre Dualvalcost Solution SRL, prin intermediul ww.dualcv.net catre Cumparator si pot fi modificate numai cu acordul expres scris al ambelor parti.</p>
                               <p>In acest TCGV, urmatorii termeni vor insemna:</p>
                               <p>
                                   <ul>
                                        <li>Cumparator: persoana, firma, companie sau alta entitate juridica care emite o Comanda.</li>
                                        <li>Vanzator: societate comerciala Dualvalcost Solution SRL, avand sediul social in Str. Garii, nr. 24, Cretesti, Ilfov, Romania</li>
                                        <li>Bunuri si Servicii: orice proiect la cheie sau serviciu, inclusiv documentele si serviciile mentionate in Comanda care urmeaza a fi furnizate de catre Vanzator Cumparatorului.</li>
                                        <li>Comanda: un document electronic ce intervine ca forma de comunicare intre Vanzator si Cumparator prin care Vanzatorul este de acord sa livreze Serviciile si Cumparatorul este de acord sa primeasca aceste Servicii, efectuand plata acestora.</li>
                                        <li>Comanda: un document electronic ce intervine ca forma de comunicare intre Vanzator si Cumparator prin care Vanzatorul este de acord sa livreze Serviciile si Cumparatorul este de acord sa primeasca aceste Servicii, efectuand plata acestora.</li>
                                        <li>Drepturi de proprietate intelectuala (in cele ce urmeaza DPI): toate drepturile imateriale cum ar fi know-how, dreptul de autor si drepturi in natura de autor, drepturile de baza de date, drepturi de proiectare, drepturi de model, patente, marci inregistrate si inregistrari ale numelor de domenii pentru oricare din cele de mai sus.</li>
                                        <li>Specificatii: toate specificatiile si/sau descrierile serviciilor asa cum sunt precizate in comanda.</li>
                                   </ul>
                               </p>
                               <p><strong>2 - DOCUMENTE CONTRACTUALE</strong></p>
                               <p>Prin lansarea unei comenzi electronice sau telefonice pe site-ul ocshop.ro, Cumparatorul este de acord cu forma de comunicare (telefonic sau e-mail) prin care Vanzatorul isi deruleaza operatiunile. Comanda va fi compusa din urmatoarele documente, in ordinea importantei:</p>
                               <p>
                                   <ul>
                                        <li>Comanda (impreuna cu mentiunile clare asupra datelor de livrare si facturare)</li>
                                        <li>Specificatiile Cumparatorului (acolo unde este cazul)</li>
                                        <li>TCGV</li>
                                   </ul>
                               </p>
                               <p>Daca Vanzatorul confirma comanda, acest lucru va implica o acceptare completa a termenilor Comenzii. Acceptare comenzii de catre Vanzator se considera finalizata atunci cand exista o confirmare verbala (telefonica) sau electronica (e-mail) din partea Vanzatorului catre Cumparator, fara a necesita o confirmare de primire din partea acestuia. Vanzatorul nu considera in nici un moment o comanda neconfirmata ca avand valoarea unui Contract .</p>
                               <p><strong>3 - VALABILITATE</strong></p>
                               <p>Prezentul Contract intra in vigoare la emiterea facturii de catre Vanzator. Informarea despre emiterea facturii se face telefonic sau electronic (e-mail). Termenii si conditiile generale de vanzare vor sta la baza Contractului astfel incheiat, in completarea acestora fiind oferta emis de catre Vanzator sau un furnizor al acestuia.</p>
                               <p>4 - EXTINDEREA OBLIGATIILOR VANZATORULUI</p>
                               <p>Vanzatorul isi va utiliza cunostintele sale profesionale si tehnice pentru a atinge rezultatul stipulat in Comanda si va livra Bunurile si Serviciile care indeplinesc cerintele, nevoile si specificatiile Cumparatorului.</p>
                               <p>Informatiile prezentate pe site-urile vanzatorului au caracter informativ si sunt documentate sau modificate de catre Vanzator conform fiselor/site-urilor de prezentare ale Producatorilor. De asemenea, din considerente legate de spatiu si coerenta structurii informatiei, descrierile produselor pot fi incomplete insa vanzatorul face eforturi de a prezenta informatiile cele mai relevante conform informatiilor primite de la Producatori, pentru ca produsul sa fie utilizat in parametrii pentru care a fost achizitionat.</p>
                               <p>Informatiile prezentate pe site-urile vanzatorului au caracter informativ si sunt documentate sau modificate de catre Vanzator conform fiselor/site-urilor de prezentare ale Producatorilor. De asemenea, din considerente legate de spatiu si coerenta structurii informatiei, descrierile produselor pot fi incomplete insa vanzatorul face eforturi de a prezenta informatiile cele mai relevante conform informatiilor primite de la Producatori, pentru ca produsul sa fie utilizat in parametrii pentru care a fost achizitionat.</p>
                               <p><strong>5. - CESIONAREA SI SUBCONTRACTAREA</strong></p>
                               <p>Vanzatorul poate cesiona si/sau subcontracta o terta parte pentru servicii ce tin de onorarea comenzii, cu informarea Cumparatorului, nefiind necesar acordul acestuia. Vanzatorul va fi intotdeauna responsabil fata de Cumparator pentru toate obligatiile contractuale.</p>
                               <p><strong>6 - DREPTUL DE PROPRIETATE INTELECTUALA SI INDUSTRIALA (DPI)</strong></p>
                               <p>Cumparatorul intelege dreptul de proprietate intelectuala si nu va dezvalui unei terte parti sau va face publice (pe internet sau media), nici una dintre informarile primite de la Vanzator. De asemenea, numele site-urilor precum si insemnele grafice, sunt marci inregistrate in proprietatea si nu pot fi preluate, copiate sau folosite, fara acordul scris al proprietarului.</p>
                               <p>7 - CONFIDENTIABILITATE - PUBLICITATE</p>
                               <p>Toate planurile, documentele si informatiile de orice natura furnizate de catre Cumparator Vanzatorului, incluzand fara a limita comanda, vor ramane in proprietatea Vanzatorului. Ele pot fi utilizate numai pentru executarea contractului. Nici o declaratie publica, promovare, comunicat de presa sau orice alt mod de dezvaluire catre terte parti nu va fi facuta de Cumparator cu privire la comanda fara consimtamantul prealabil scris al Vanzatorului .</p>
                               <p><strong>8 - TERMENE PENALITATI</strong></p>
                               <p>In cazul in care nu pot fi respectate termenele de livrare si/sau pornire a Comenzii, Vanzatorul este obligat sa anunte Cumparatorul de termenul estimat de finalizare a livrarii. Cumparatorul va avea dreptul sa revendice daune suplimentare de la Vanzator, cand este permis de lege, in cazul neindeplinirii totale sau partiale din partea vanzatorului a executarii Contractului in conformitate cu termenele stabilite. In cazul in care Cumparatorul intarzie din vina sa faca plata serviciilor in termenul prevazut in factura emisa de Vanzator, este obligat la plata unei penalitati de 0,5% pe zi din suma datorata. In cazul in care Vanzatorul primeste informatii eronate legate de facturarea sau livrarea produselor, se va stabili un nou termen de onorare a comenzii, acesta incadrandu-se in termen de 3 zile lucratoare. Termenul de finalizare, daca nu este modificat de Vanzator prin anuntarea Cumparatorului, este de 60 de zile de la plasarea comenzii.</p>
                               <p><strong>9 - FACTURARE - PLATI</strong></p>
                               <p>Pretul, modalitatea de plata si termenul de plata sunt specificate in Comanda. Vanzatorul va emite catre Cumparator o factura pentru Bunurile si Serviciile livrate, obligatia Cumparatorului fiind sa furnizeze toate informatiile necesare emiterii facturii conform cu legislatia in vigoare.</p>
                               <p>9.1 Odata ce plata a fost efectuata catre Vanzator, prezentul acord devine contract din punct de vedere juridic. Conform legii 365 din 2002 art.9. </p>
                               <p>9.2 Preturile afisate pe ocshop.ro pot fi modificate fara o anuntare prealabila a utilizatorilor. Preturile nu sunt cu titlu de oferta garantata si nelimitata in timp.</p>
                               <p>9.3 Preturile pachetelor si serviciilor oferite sunt insotite de specificatii tehnice. In cazul in care beneficiarul solicita alte servicii cu alte specificatii tehnice, Dualvalcost Solution SRL poate oferi sau nu un pret diferit decat cel afisat pe site.</p>
                               <p><strong>10 - RISCURI SI RESPONSABILITATI</strong></p>
                               <p>Livrarea serviciilor</p>
                               <p>Vanzatorul se obliga sa furnizeze Bunurile si Serviciile in termenele stipulate in contractul de colaborare din parti.</p>
                               <p><strong>11 - ACCEPTARE</strong></p>
                               <p>Acceptarea va fi facuta atunci cand Bunurile si Serviciile sunt conforme cu caracteristicile tehnice mentionate in Comanda. In cazul in care Cumparatorul descopera ca Produsele livrate sau Serviciile furnizate nu sunt conforme specificatiilor tehnice, atunci Vanzatorul va aduce la conformitate Produsele si Serviciile in termen maxim egal cu termenul de executie al Comenzii, fara a imputa Cumparatorului eventuale costuri legate de aceste operatiuni. De asemenea, Vanzatorul va respecta prevederile Legii 51/2003 pentru aprobarea Ordonantei Guvernului nr. 130/2000 privind regimul juridic al contractelor la distanta, ceea ce confera Cumparatorului (numai in conditiile actului normativ mentionat) dreptul ca in termen de 10 zile sa denunte unilateral Contractul, urmand sa primeasca in termen de 30 de zile de la denuntarea unilaterala in scris (document semnat de Cumparator si transmis electronic sau prin posta cu confirmare de primire) pretul Contractului, conditionat de returnarea Bunurilor si Serviciilor. Vanzatorul are dreptul ca atunci cand considera ca actiunile Cumparatorului au fost cu rea intentie sa solicite daune interese catre Cumparator, in conditiile legislatiei.</p>
                               <p><strong>12 - GARANTII</strong></p>
                               <p>Pe langa orice alte garantii prevazute de legile aplicabile si detaliate in Certificatul de Garantie emis de catre Vanzator sau de un furnizor al Vanzatorului, acestea garanteaza Cumparatorul impotriva oricarei non conformitati care poate afecta intreaga sau o parte a Bunurilor si Serviciilor, cu exceptia uzurii normale, pentru a perioada de 12 luni de la data emiterii facturii de vanzare.</p>
                               <p><strong>13 - TRANSFERUL PROPRIETATII</strong></p>
                               <p>Proprietatea asupra Bunurilor si Serviciilor va fi transferate electronic la momentul efectuarii platii din partea Cumparatorului pe adresele de email furnizate de Cumparator.</p>
                               <p><strong>14 - RESPECTAREA LEGILOR SI STANDARDELOR</strong></p>
                               <p>Vanzatorul va respecta toate legile, reglementarile si ordonantele aplicabile la realizarile sale contractuale, inclusiv fara limitare la fabricarea sau livrarea Bunurilor si Serviciilor.</p>
                               <p><strong>15 - RASPUNDERE</strong></p>
                               <p>Vanzatorul nu poate fi responsabil pentru daune de orice fel pe care Cumparatorul sau oricare terta parte o poate suferi ca rezultat al indeplinirii de catre Vanzator a oricarei din obligatiile sale conform Comenzii si pentru daune care rezulta din utilizarea Bunurilor si Serviciilor dupa livrarea acestora. Vanzatorul va fi raspunzator in cazul in care sub-contractantii si/sau partenerii sai de orice fel implicati in executarea Comenzii nu indeplinesc oricare din obligatiile contractuale.</p>
                               <p><strong>16 - PRET DERIZORIU</strong></p>
                               <p>Vanzatorul face toate eforturile in a furniza informatii corecte despre pretul si caracteristicile produselor. E posibil ca unele preturi sa fie eronate. In cazul inregistrarii unei comenzi cu un pret derizoriu, Vanzatorul are dreptul sa anuleze comanda Cumparatorului.</p>
                               <p><strong>17 - INCALCARE - TERMINARE</strong></p>
                               <p>Daca vanzatorul nu executa obligatiile sale, inclusiv in perioada de garantie, Cumparatorul va notifica Vanzatorul asupra acestei neindepliniri. Un plan de actiune va fi validat intre Parti in termen de 30 zile de la notificare. Cumparatorul poate sa anuleze o Comanda prin e-mail, inainte ca aceasta sa fi fost livrata. In caz contrar, comanda va face obiectul returnarii bunurilor, mentionat pe siteurile Vanzatorului</p>
                               <p><strong>18 - FORTA MAJORA</strong></p>
                               <p>Nici una din parti nu va fi raspunzatoare pentru neexecutarea obligatiilor sale contractuale, daca o astfel de neexecutare este datorata unui eveniment de forta majora. Forta majora este evenimentul imprevizibil, in afara controlului partilor si care nu poate fi evitat.</p>
                               <p><strong>19 - CONFIDENTIALITATEA DATELOR</strong></p>
                               <p>Te rog sa parcurgi <a title="Politica de Confidentialitate" href="http://ocshop.ro/politica-de-confidentialitate">Politica de Confidentialitate</a> cu privire la prelucrarea datelor cu caracter personal, care face parte din prezentul Document.</p>
                               <p><strong>20 - FOLOSIREA COOKIE-URILOR</strong></p>
                               <p>Vezi <a title="Politica de Cookies" href="http://ocshop.ro/politica-de-utilizare-a-cookie-urilor">Politica de Cookies</a>, care face parte din prezentul Document.</p>
                               <p><strong>21 - LEGEA APLICABILA - JURISDICTIA</strong></p>
                               <p>Prezentul contract este supus legii romane. Orice litigii nascute din interpretarea si executarea acestui contract se vor solutiona pe cale amiabila, iar in cazul in care nu se ajunge la un acord pe aceasta cale se va apela la instantele judecatoresti competente de la sediu Vanzatorului.</p>
                               <p><strong>22 - PREVEDERI DIVERSE</strong></p>
                               <p>Daca una sau mai multe prevederi ale prezentelor TCGV sunt in conflict cu oricare cerinta legal aplicabila, numite prevederi nu vor fi aplicate si Partile de vor stradui impreuna sa convina asupra unor noi prevederi care sa respecte spiritul prevederilor initiale. Partile in contract vor fi considerate cantractanti independenti si nici uneia din parti nu i se acorda dreptul sau autoritatea de a asuma sau crea orice obligatie pe seama sau in dauna celeilalte. Termenii si conditiile din acest contract inlocuiesc alte intelegeri anterioare scrise sau verbale, dintre Partile amintite, referitoare la subiectul prezentului Contract si nu pot fi modificate sau schimbate decat prin intelegere scrisa semnata de ambele parti.</p>
                              </Col>
                         </Row>
                    </Container>                   
               </div>

            </div>
    		
          )
     }
}


export default Termeni 