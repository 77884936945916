import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import '../App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet";

class Confidentialitate extends Component {
     render(){
     
          return(

            <div>

            <Helmet>
                <title>Politica de confidențialitate - OcShop.ro</title>
                <meta
                name="description"
                content="Politica de confidențialitate pentru utilizarea site-ului OcShop.ro"
                />;
                <link rel="canonical" href="http://ocshop.ro/politica-de-confidentialitate" />
            </Helmet>    

               <div class="headings">
                    <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                              <h1>Politica de confidențialitate</h1>
                              <p>Politica de confidențialitate pentru utilizarea site-ului OcShop.ro</p>
                              </Col>
                         </Row>
                    </Container>
               </div>

               <div class="content">
               <Container>
                         <Row>
                              <Col xs={12} md={12} lg={12}>
                                   <h2>DATE DE IDENTIFICARE</h2>
                                   <p><strong>S.C. Dualvalcost Solution SRL</strong></p>
                                   <p><strong>Adresa: </strong>Str. Garii, nr. 24, Cretesti, Ilfov, Romania</p>
                                   <p><strong>CUI: </strong>26823086</p>
                                   <p><strong>Reg. Com.: </strong>J23/1237/2010</p>
                                   <p><strong>Email: </strong>contact@ocshop.ro</p>
                                   <p>Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, modificata si completata, Dualvalcost Solution SRL are obligatia de a administra in conditii de siguranta si numai pentru scopurile specificate, datele personale pe care ni le furnizati despre dumneavoastra.</p>
                                   <p>Scopul colectarii datelor este: informarea utilizatorilor privind situatia contului de pe ocshop.ro, informarea utilizatorilor/clientilor privind evolutia si starea comenzilor, evaluarea produselor si serviciilor oferite, pentru reclama, marketing si publicitate, statistica.</p>
                                   <p>ocshop.ro nu promoveaza SPAM-ul. Orice utilizator care a furnizat explicit adresa sa de email pe site-ul ocshop.ro poate opta pentru stergerea acesteia din baza noastra de date.</p>
                                   <p>Datele dumneavoastra cu caracter personal vor fi utilizate in scopul pentru care le-ati furnizat, pentru a administra, a sprijini si a evalua serviciile noastre si a preintampina incalcarea securitatii, a legii si a termenilor nostri contractuali.</p>
                                   <p>De asemenea, Dualvalcost Solution SRL nu va furniza datele dumneavoastra cu caracter personal altor companii cu care se afla in relatii de parteneriat, dar numai dupa ce ne-am asigurat printr-un contract ca aceste informatii sunt pastrate in siguranta si ca furnizarea acestor informatii personale se face conform legislatiei in vigoare, dupa cum urmeaza: furnizori de servicii de servicii de marketing, telemarketing sau alte servicii, alte companii cu care putem dezvolta programe comune de ofertare pe piata a produselor si serviciilor noastre, asiguratori.</p>
                                   <p>Informatiile dumneavoastra cu caracter personal pot fi furnizate si catre Parchet, Politie, Instantele judecatoresti si altor organe abilitate ale statului, in baza si in limitele prevederilor legale si ca urmare a unor cereri expres formulate.</p>
                                   <p>Conform Legii nr. 677/2001, beneficiati de dreptul de acces, de interventie asupra datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a va adresa justitiei. Totodata, aveti dreptul sa va opuneti prelucrarii datelor personale care va privesc si sa solicitati stergerea datelor cu exceptia situatiilor prevazute de lege.</p>
                                   <p><strong>ACORDUL cu privire la prelucrarea datelor cu caracter personal</strong></p>
                                   <p>Avand in vedere prevederile Regulamentului (UE) 2016/679 al Parlamentului European si al Consiliului din 27 aprilie 2016, privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si libera circulatie a acestor date si abrogarea Directivei 95/46/CE, societatea Dualvalcost Solution SRL doreste sa isi asume angajamentul cu privire la respectarea legislatiei aplicabile domeniului datelor cu caracter personal astfel incat sa ne bucuram in continuare de increderea dumneavoastra.</p>
                                   <p>Aceasta nota de informare este detalierea a cum, cand si de ce va prelucr?m datele cu caracter personal atunci cand solicitati un produs/serviciu din site-ul web ocshop.ro</p>
                                   <p>Daca aveti orice intrebare privind modalitatea in care va prelucram datele dumneavoastr? cu caracter personal:</p>
                                   <p>- va puteti adresa Responsabilului cu Protectia Datelor Dualvalcost Solution SRL la adresa de e-mail contact@ocshop.ro</p>
                                   <p>sau</p>
                                   <p>- ne puteti contacta prin posta la adresa Str. Garii, nr. 24, Cretesti, Ilfov, Romania</p>
                                   <p>ACORD PRIVIND PRELUCRAREA SI STOCAREA DATELOR CU CARACTER PERSONAL CONFORM REGULAMENTULUI 2016/679/UE aplicabil din 25 Mai 2018</p>
                                   <p>Dualvalcost Solution SRL, cu sediul în Str. Garii, nr. 24, Cretesti, Ilfov, Romania, avand numar de ordine in Registrul Comertului J23/237/2010, cod unic de inregistrare 26823086, denumita în continuare Prestator</p>
                                   <p>si</p>
                                   <p>Societatea comerciala / persoana fizica (clientul) accepta prelucrarea si stocarea datelor cu caracter personal comunicate catre Dualvalcost Solution SRL (cod numeric personal, CIF/CUI, Nr. Reg Comertului, adresa, numar de telefon, email), in conditiile urmatoare:</p>
                                   <p>1. Temeiul prelucrarii/stocarii acestor date personale (nume, adresa, CNP, telefon, email, banca, cont bancar) este constituit din contractele semnate si prevederile legale aplicabile.</p>
                                   <p>Astfel, pentru a facilita desfasurarea activitatilor aflate in legatura cu aceste contracte si in vederea indeplinirii obligatiilor legale/contractuale, Beneficiarul accepta folosirea acestor date cu caracter personal de catre Dualvalcost Solution SRL in anumite activitati specifice, cum ar fi: facturare, avizare, livrare, notificare prin email si sms, acces pe site-ul ocshop.ro (aria clienti), etc. detinut de Dualvalcost Solution SRL, Nr.Reg.Com. J23/1237/2010, CUI 26823086, cu sediul in Str. Garii, nr. 24, Cretesti, Ilfov, Romania</p>
                                   <p>2. Durata folosirii si stocarii acestor date cu caracter personal este durata contractuala pana la expirarea obligatiilor contractuale si/sau a termenelor de arhivare ale societatii Dualvalcost Solution SRL</p>
                                   <p>Ca regula generala, vom stoca datele dvs. cu caracter personal furnizate pe site-ul Dualvalcost Solution SRL pentru defasurarea activitatilor companiei. Puteti sa ne solicitati oricand stergerea anumitor informatii sau inchiderea contului si vom da curs acestor solicitari, sub rezerva pastrarii anumitor informatii inclusiv ulterior inchiderii contului, in situasiile in care legislatia aplicabila sau interesele noastre legitime o impun.</p>
                                   <p>3. Datele cu caracter personal mentionate in actele cu caracter comercial/contabil vor fi stocate pe durata specificata de legislatia in vigoare.</p>
                                   <p>4. Dupa expirarea acestor termene, veti putea solicita in scris (email, postal) stergerea definitiva a acestor date cu caracter personal din programele de evidenta ale societatii Dualvalcost Solution SRL</p>
                                   <p>5. Aceste date cu caracter personal nu pot fi comunicate de catre Dualvalcost Solution SRL unui tert decat cu confirmarea scrisa a Beneficiarului si/sau in cazurile prevazute expres de legislatia in vigoare. Aceste date sunt listate pe actele societatii Dualvalcost Solution SRL (facturi, contracte, avize, awb-uri,etc...) si sunt stocate pe serverele, soft-urile si site-urile Prestatorului. Prestatorul poate specifica anumite date cu caracter personal in rapoartele impuse de legislatia in vigoare (ex: Jurnale TVA, D394, etc...)</p>
                                   <p>6. Beneficiarul (clientul) are dreptul de a fi informat asupra prelucarii datelor cu caracter personal de catre Prestator</p>
                                   <p>7. Beneficiarul (clientul) are dreptul de a primi datele personale intr-un format structurat, utilizat in mod curent, care poate fi citit automat, avand dreptul ca aceste date sa fie transmise direct altui operator, daca acest lucru este fezabil din punct de vedere tehnic</p>
                                   <p>8. Beneficiarul (clientul) are dreptul de a se opune in scris prelucrarii datelor cu caracter personal (ex: daca scopul prelucrarii este marketingul direct, etc.)</p>
                                   <p>9. Beneficiarul (clientul) are dreptul de a corecta in scris datele cu caracter personal inexacte</p>
                                   <p>10. Beneficiarul (clientul) are dreptul de a solicita in scris stergerea datele cu caracter persoanal in cazul in care acestea nu mai sunt necesare indeplinirii scopurilor pentru care au fost colectare sau prelucrate si nu exista nici un alt temei juridic de prelucrare a acestor date in continuare</p>
                                   <p>11. Beneficiarul (clientul) poate restrictiona prelucrarea datelor cu caracter personal in cazul in care contesta exactitatea datelor, pe o perioada care permite Prestatorului verificarea corectitudinii datelor.</p>
                                   <p>12. Acest acord se aplica asupra oricaror date cu caracter personal comunicate de catre Beneficiar (client) pe durata aplicarii contractelor semnate cu Dualvalcost Solution SRL, atat inaintea acestuia acord cat si dupa semnarea acestui acord, in baza regulamentului 2016/679/UE aplicabil din data de 25 Mai 2018</p>
                                   <p>Reamintim faptul ca puteti contacta in orice moment Responsabilul Dualvalcost Solution SRL cu protectia datelor prin transmiterea solicitarii dvs prin oricare dintre urmatoarele modalitati:</p>
                                   <p>– prin e-mail la adresa: contact@ocshop.ro</p>
                                   <p>sau</p>
                                   <p>– prin posta sau curier la adresa: Str. Garii, nr. 24, Cretesti, Ilfov, Romania cu mentiunea in atentia Responsabilului cu protectia datelor</p>
                              </Col>
                         </Row>
                    </Container>          
               </div>

            </div>
    		
          )
     }
}


export default Confidentialitate 