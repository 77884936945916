import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import "./Call.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'


class Call extends Component {
  render(){
       return(
            <div class="calltoaction">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <h5>Începe astăzi să vinzi online cu OcShop</h5>
                            <Button href="tel:+40763418689" variant="primary">
                                Cere ofertă
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                </svg>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
       )
  }
}

export default Call 