import React,{ Component } from 'react'
import {BrowserRouter,Routes,Route,Link,} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'

class Footer extends Component {
     render(){
          return(

            <footer>
                <Container>
                    <Row>
                        <Col xs={6} md={6} lg={3}>
                            <h3>Despre OCShop</h3>
                            <ul>
                                <li><a title="Funcționalități" href="/functionalitati-magazin-online">Funcționalități</a></li>
                                <li><a title="Module și integrări" href="/module-integrari-magazine-online">Module și integrări</a></li>
                                <li><a title="Magazin online la cheie" href="/magazin-online-la-cheie">Magazin online la cheie</a></li>
                                <li><a title="Preț magazin online" href="/pret-magazin-online">Preț magazin online</a></li>
                                <li><a title="Contact" href="/contact">Contact</a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <h3>Resurse</h3>
                            <ul>
                                <li><a title="Politică de confidențialitate" href="/politica-de-confidentialitate">Politică de confidențialitate</a></li>
                                <li><a title="Politica de utilizare a Cookie-urilor" href="/politica-de-utilizare-a-cookie-urilor">Politica de utilizare a Cookie-urilor</a></li>
                                <li><a title="Termeni și condiții" href="/termeni-si-conditii">Termeni și condiții</a></li>
                                <li><a title="ANPC Soluționarea Alternativă a Litigiilor" target="_blank" href="https://anpc.ro/ce-este-sal/"><img alt="ANPC Soluționarea Alternativă a Litigiilor" title="ANPC Soluționarea Alternativă a Litigiilor" width="175" height="50" src="https://ocshop.ro/assets/img/SAL.svg"></img></a></li>
                                <li><a title="Soluționarea online a litigiilor" target="_blank"  href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"><img alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor" width="175" height="50" src="https://ocshop.ro/assets/img/SOL.svg"></img></a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <h3>Contact</h3>
                            <p>contact@ocshop.ro</p>
                            <p>+40 763 418 689</p>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <h3>Ne găsești pe</h3>
                            <a title="Facebook" target="_blank" href="https://www.facebook.com/profile.php?id=100063575082492">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                   <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>
                            </a>
                            <a title="Twiter" target="_blank" href="https://twitter.com/dualcv">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                </svg>
                            </a>
                            <a title="Pinterest" target="_blank" href="https://www.pinterest.com/dualvalcost/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pinterest" viewBox="0 0 16 16">
                                   <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
                                </svg>
                            </a>
                            <a title="Linkedin" target="_blank" href="https://www.linkedin.com/company/dualvalcost-solution">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                </svg>
                            </a>
                        </Col>
                    </Row>
                </Container>
                <script id="CookieDeclaration" src="https://consent.cookiebot.com/7fed23b9-cc32-4ec3-ac94-6cfd2403b33d/cd.js" type="text/javascript" async></script>
            </footer>

          )
     }
}

export default Footer 