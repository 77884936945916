import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import "./About.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'


class About extends Component {
  render(){
       return(
            <div class="about">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <img src="https://ocshop.ro/assets/img/despre.webp" alt="Despre OcShop" title="Despre OcShop" width="528" height="556" />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <p><span>DESPRE NOI</span></p>
                            <h3>Magazine online la cheie dezvoltate în OpenCart</h3>
                            <p>Suntem o agenție de web design din România cu o experiență de 12 ani în dezvoltarea și promovarea magazinelor online.</p>
                            <p>Dispunem de magazine online la cheie dezvoltate, configurate și optimizate spre vânzarea produselor.</p>
                            <blockquote>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
                                    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
                                </svg>
                                <h4>Magazine online profesionale <br/> pregătite să vândă în orice moment!</h4>
                            </blockquote>
                        </Col>
                    </Row>
                </Container>
            </div>
       )
  }
}

export default About 