import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";

import "./Clients.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'


class Clients extends Component {
  render(){
       return(
            <div class="clients">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <h4>Au ales crearea unui magazin online <br/>dezvoltat cu OcShop</h4>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={6} md={3} lg={3}>
                            <a href="https://aprozarlanico.ro/" title="Aprozar online cu livrare în București - aprozarlanico.ro" target="_blank"><Image title="Aprozar online cu livrare în București - aprozarlanico.ro" alt="Aprozar online cu livrare în București - aprozarlanico.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/aprozarlanico.webp"/></a>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <a href="https://grandsecret.ro/" title="Magazin online cu usi de interior și exterior - grandsecret.ro" target="_blank"><Image title="Magazin online cu usi de interior și exterior - grandsecret.ro" alt="Magazin online cu usi de interior și exterior - grandsecret.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/grandsecret.webp"/></a>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <a href="https://tepromovezi.ro/" title="Marketplace pentru afacerile mici - tepromovezi.ro" target="_blank"><Image title="Marketplace pentru afacerile mici - tepromovezi.ro" alt="Marketplace pentru afacerile mici - tepromovezi.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/tepromovezi.ro.webp"/></a>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                            <a href="https://kiddyshop.ro/" title="Magazin online cu produse pentru copii - kiddyshop.ro" target="_blank"><Image title="Magazin online cu produse pentru copii - kiddyshop.ro" alt="Magazin online cu produse pentru copii - kiddyshop.ro" width="370" height="auto" src="https://ocshop.ro/assets/img/portfolio/kiddyshop.ro.webp"/></a>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Button href="tel:+40763418689" variant="primary" className="suna_acum">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg> 
                            Te-ai decis? Hai să vorbim
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
       )
  }
}

export default Clients 